import { LanguageCode } from "../hooks/useLanguage";

export interface ProductGroup {
  mainGroupId: string;
  mainGroupName: {
    [LanguageCode.FI]: string;
    [LanguageCode.SV]: string;
    [LanguageCode.EN]: string;
  };
  productGroupId: string;
  productGroupName: {
    [LanguageCode.FI]: string;
    [LanguageCode.SV]: string;
    [LanguageCode.EN]: string;
  };
}

export interface ProductGroupOption {
  id: string;
  name: {
    [LanguageCode.FI]: string;
    [LanguageCode.SV]: string;
    [LanguageCode.EN]: string;
  };
  productGroupIds: string[];
}
export const productGroupsForOpenWish: ProductGroupOption[] = [
  {
    id: "redwines",
    name: {
      [LanguageCode.FI]: "Punaviinit",
      [LanguageCode.SV]: "Rödvin",
      [LanguageCode.EN]: "Red wine",
    },
    productGroupIds: ["productGroup_110"],
  },
  {
    id: "rosewines",
    name: {
      [LanguageCode.FI]: "Roseeviinit",
      [LanguageCode.SV]: "Rosévin",
      [LanguageCode.EN]: "Rosé wine",
    },
    productGroupIds: ["productGroup_120"],
  },
  {
    id: "whitewines",
    name: {
      [LanguageCode.FI]: "Valkoviinit",
      [LanguageCode.SV]: "Vitvin",
      [LanguageCode.EN]: "White wine",
    },
    productGroupIds: ["productGroup_130"],
  },
  {
    id: "sparkling-champagne",
    name: {
      [LanguageCode.FI]: "Kuohuviinit ja Samppanjat",
      [LanguageCode.SV]: "Mousserande vin & Champagne",
      [LanguageCode.EN]: "Sparkling wine & Champagne",
    },
    productGroupIds: ["productGroup_B10"],
  },
  {
    id: "dessert-other",
    name: {
      [LanguageCode.FI]: "Jälkiruokaviinit, väkevöidyt ja muut viinit",
      [LanguageCode.SV]: "Dessertvin, starkvin och andra viner",
      [LanguageCode.EN]: "Dessert wine & other fortified wines",
    },
    productGroupIds: [
      "productGroup_J10",
      "productGroup_160",
      "productGroup_195",
      "productGroup_310",
      "productGroup_320",
      "productGroup_330",
      "productGroup_340",
      "productGroup_350",
      "productGroup_360",
      "productGroup_370",
      "productGroup_H30",
      "productGroup_S10",
      "productGroup_S60",
    ],
  },
  {
    id: "beer",
    name: {
      [LanguageCode.FI]: "Oluet",
      [LanguageCode.SV]: "Öl",
      [LanguageCode.EN]: "Beer",
    },
    productGroupIds: ["productGroup_600"],
  },
  {
    id: "cider",
    name: {
      [LanguageCode.FI]: "Siiderit",
      [LanguageCode.SV]: "Cider",
      [LanguageCode.EN]: "Cider",
    },
    productGroupIds: ["productGroup_645"],
  },
  {
    id: "mixeddrinks",
    name: {
      [LanguageCode.FI]: "Juomasekoitukset",
      [LanguageCode.SV]: "Drinkar",
      [LanguageCode.EN]: "Mixed drinks",
    },
    productGroupIds: ["productGroup_650"],
  },
  {
    id: "vodka-spirits",
    name: {
      [LanguageCode.FI]: "Vodkat ja viinat",
      [LanguageCode.SV]: "Vodka och brännvin",
      [LanguageCode.EN]: "Vodka & spirit",
    },
    productGroupIds: ["productGroup_411", "productGroup_413", "productGroup_415", "productGroup_S90"],
  },
  {
    id: "gin-other",
    name: {
      [LanguageCode.FI]: "Ginit ja muut viinat",
      [LanguageCode.SV]: "Gin och övriga brännvin",
      [LanguageCode.EN]: "Gin and other spirits",
    },
    productGroupIds: [
      "productGroup_418",
      "productGroup_419",
      "productGroup_420",
      "productGroup_421",
      "productGroup_422",
      "productGroup_423",
      "productGroup_424",
      "productGroup_425",
      "productGroup_S30",
    ],
  },
  {
    id: "rum",
    name: {
      [LanguageCode.FI]: "Rommit",
      [LanguageCode.SV]: "Rom",
      [LanguageCode.EN]: "Rum",
    },
    productGroupIds: ["productGroup_428"],
  },
  {
    id: "cognac",
    name: {
      [LanguageCode.FI]: "Konjakit",
      [LanguageCode.SV]: "Konjak",
      [LanguageCode.EN]: "Cognacs",
    },
    productGroupIds: ["productGroup_432"],
  },
  {
    id: "brandy-armagnac-calvados",
    name: {
      [LanguageCode.FI]: "Brandyt, Armanjakit ja Calvadosit",
      [LanguageCode.SV]: "Brandy, Armagnac och Calvados",
      [LanguageCode.EN]: "Brandy, Armagnac & Calvados",
    },
    productGroupIds: ["productGroup_436", "productGroup_437", "productGroup_439", "productGroup_S20"],
  },
  {
    id: "whisky",
    name: {
      [LanguageCode.FI]: "Viskit",
      [LanguageCode.SV]: "Whisky",
      [LanguageCode.EN]: "Whisky",
    },
    productGroupIds: ["productGroup_440"],
  },
  {
    id: "liquer-bitter",
    name: {
      [LanguageCode.FI]: "Liköörit ja katkerot",
      [LanguageCode.SV]: "Likör och bitter",
      [LanguageCode.EN]: "Liqueur & bitter",
    },
    productGroupIds: [
      "productGroup_470",
      "productGroup_F10",
      "productGroup_F15",
      "productGroup_F20",
      "productGroup_F25",
      "productGroup_F30",
      "productGroup_F40",
      "productGroup_F50",
      "productGroup_F60",
      "productGroup_F70",
      "productGroup_F80",
      "productGroup_S40",
    ], // liköörit ja katkerot
  },
  {
    id: "nonalcoholic",
    name: {
      [LanguageCode.FI]: "Alkoholittomat",
      [LanguageCode.SV]: "Alkoholfritt",
      [LanguageCode.EN]: "Non-alcoholic",
    },
    productGroupIds: [
      "productGroup_710",
      "productGroup_910",
      "productGroup_920",
      "productGroup_930",
      "productGroup_940",
      "productGroup_950",
      "productGroup_960",
      "productGroup_965",
    ],
  },
  {
    id: "gifts-accessories",
    name: {
      [LanguageCode.FI]: "Lahja- ja juomatarvikkeet",
      [LanguageCode.SV]: "Presenter och dryckestillbehör",
      [LanguageCode.EN]: "Gifts & accessories",
    },
    productGroupIds: ["productGroup_901", "productGroup_902", "productGroup_903"],
  },
];
