import React, { useEffect, useState, useRef, lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Wish, WishStatus } from "../api/wishes";
import { Header } from "../components/Header/Header";
import { useLanguage } from "../hooks/useLanguage";
import { RootState, useAppDispatch } from "../store";
import { LoadingState } from "../store/loadingState";
import { clearProducts, fetchProducts } from "../store/products";
import { createWish } from "../store/wishes";
import { ContentWrapper } from "./Home.styled";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import { parse } from "query-string";
import { useAllStores } from "../hooks/useAllStores";
import * as Prismic from "../components/PrismicContent";
import { FormInputs, OpenWishForm } from "../components/OpenWishForm/OpenWishForm";
import { Link } from "@material-ui/core";
const ProductSearch = lazy(() => import("../components/ProductSearch/ProductSearch"));
const StoreSelector = lazy(() => import("../components/StoreSelector/StoreSelector"));

const scrollToRef = (ref: any) => window.scrollTo({ left: 0, top: ref.current.offsetTop, behavior: "smooth" });

export const Home = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const { lang, setLang } = useLanguage();
  const products = useSelector((state: RootState) => state.products.products);
  const potId = useSelector((state: RootState) => state.products.potId);
  const loadingProducts = useSelector((state: RootState) => state.products.loading);
  const searchSuccess = loadingProducts === LoadingState.Succeeded;
  const homepageContent = useSelector((state: RootState) => state.content.content?.homepage);

  const [searchText, setSearchText] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const { storeId } = useParams<{ storeId?: string }>();

  const [selectedStore, setSelectedStore] = useState(storeId);
  const singleStoreMode = !!storeId;
  const isStoreSelected = selectedStore !== "";
  const isProductSelected = selectedProduct !== "";
  const smallScreen = useMediaQuery("(max-width:768px)");
  const productSearchRef = useRef(null);
  const scrollToProductSearch = () => scrollToRef(productSearchRef);
  const productResultsRef = useRef(null);
  const scrollToProductResults = () => scrollToRef(productResultsRef);
  const productSearchInputRef = useRef<HTMLInputElement>(null);
  const sendWishButtonRef = useRef<HTMLButtonElement>(null);
  const { stores } = useAllStores();

  const [openWishFormOpen, setOpenWishFormOpen] = useState(false);
  const history = useHistory();

  const location = useLocation();
  const params = parse(location.search);
  useEffect(() => {
    if (params.lang) {
      setLang(typeof params.lang === "object" ? params.lang[0] : params.lang);
      window.location.href = "/";
    }
  }, [params, setLang]);

  useEffect(() => {
    if (selectedStore && selectedStore !== "" && searchText !== "") {
      dispatch(fetchProducts({ searchText, storeId: selectedStore, lang }));
    }
  }, [dispatch, searchText, selectedStore, lang]);

  const scrollToProductSearchTimeout = useRef(0);
  useEffect(() => {
    if (selectedStore && productSearchInputRef.current) {
      clearTimeout(scrollToProductSearchTimeout.current);
      scrollToProductSearchTimeout.current = window.setTimeout(() => {
        scrollToProductSearch();
        productSearchInputRef.current?.focus();
      }, 200);
    }
  }, [selectedStore]);

  const scrollToProductResultsTimeout = useRef(0);
  useEffect(() => {
    if (products && products.length > 0) {
      clearTimeout(scrollToProductResultsTimeout.current);
      scrollToProductResultsTimeout.current = window.setTimeout(() => {
        scrollToProductResults();
      }, 300);
    }
  }, [products]);

  useEffect(() => {
    if (selectedStore && selectedProduct) {
      sendWishButtonRef.current?.focus();
    }
  }, [selectedStore, selectedProduct]);

  const handleSearchProducts = (text: string) => {
    if (text !== "") {
      setSearchText(text);
    }
  };

  const handleCreateWish = () => {
    if (!stores || !products) return;
    const store = stores.find((store) => store.id === selectedStore);
    const product = products.find((product) => product.id === selectedProduct);

    if (!store || !product) return;
    const storeDetails = {
      id: store.id,
      name: store.name,
    };

    const productDetails = {
      id: product.id,
      name: product.name,
      mainGroupId: product.mainGroupId,
      mainGroupName: product.mainGroupName,
      productGroupId: product.productGroupId,
      productGroupName: product.productGroupName,
      price: product.price,
      agentId: product.agentId,
      agentName: product.agentName,
    };

    const wish: Wish = {
      store: storeDetails,
      product: productDetails,
      status: WishStatus.NEW,
      potId: potId,
    };

    dispatch(createWish(wish));
    setSelectedProduct("");
    setSelectedStore("");
    dispatch(clearProducts());

    setTimeout(() => {
      history.push("/thank-you", { product, storeId: singleStoreMode && storeId ? storeId : undefined });
    }, 500);
  };

  const handleCreateOpenWish = (form: FormInputs) => {
    const store = stores.find((store) => store.id === selectedStore);
    const storeDetails = store
      ? {
          id: store.id,
          name: store.name,
        }
      : { id: null, name: null };

    const wish: Wish = {
      store: storeDetails,
      openWish: {
        productGroup: form.productGroup,
        country: form.country?.value,
        wishText: form.wishText,
      },
      status: WishStatus.NEW,
      potId: potId,
    };
    dispatch(createWish(wish));
    setSelectedProduct("");
    setSelectedStore("");
    dispatch(clearProducts());

    setTimeout(() => {
      history.push("/thank-you", { storeId: singleStoreMode && storeId ? storeId : undefined });
    }, 500);
  };

  const openOpenWishForm = () => setOpenWishFormOpen(true);
  const closeOpenWishForm = () => setOpenWishFormOpen(false);

  if (!homepageContent) return null;

  return (
    <Container disableGutters maxWidth="lg">
      <Grid container direction="column" justify="flex-start" alignItems="stretch">
        <header>
          <Grid item>
            <Header logo={Prismic.getImage(homepageContent["alko-logo"], smallScreen ? "small" : null) || undefined} />
          </Grid>
        </header>

        <main>
          <Grid item>
            <Container disableGutters maxWidth="sm">
              <Box color="text.secondary" py={2} px={{ xs: 2 }}>
                {Prismic.getTitle(homepageContent["hero-selection-headline"])}
                <Box color="white" p={2}>
                  <ContentWrapper>{Prismic.getContent(homepageContent["hero-selection-paragraph"])}</ContentWrapper>
                </Box>
              </Box>
            </Container>
          </Grid>

          <Grid item>
            <Fade in={!!homepageContent} timeout={500}>
              <Box
                bgcolor="white"
                borderRadius={4}
                px={{ xs: 2, sm: 2, md: 4 }}
                py={{ xs: 4, sm: 4, md: 4 }}
                mx={{ md: 4 }}
                my={2}
                // minHeight={230}
              >
                <Suspense fallback={<div />}>
                  <StoreSelector
                    selected={selectedStore}
                    onSelect={(storeId?: string) => {
                      setSelectedStore(storeId || "");
                      setSelectedProduct("");
                    }}
                    onReset={() => {
                      setSelectedStore("");
                      setSelectedProduct("");
                      setSearchText("");
                      dispatch(clearProducts());
                    }}
                    singleStoreMode={singleStoreMode}
                  />
                </Suspense>
              </Box>
            </Fade>
          </Grid>

          {!!selectedStore && (
            <Collapse in={!!selectedStore && !openWishFormOpen} collapsedHeight={0}>
              <Fade in={!!selectedStore && !openWishFormOpen} timeout={500}>
                <Grid item>
                  <div ref={productSearchRef}>
                    <Box
                      bgcolor="white"
                      borderRadius={4}
                      px={{ xs: 0, sm: 0, md: 4 }}
                      py={{ xs: 4, sm: 4, md: 4 }}
                      mx={{ md: 4 }}
                      my={2}
                    >
                      <Suspense fallback={<div />}>
                        <ProductSearch
                          products={products}
                          selected={selectedProduct}
                          onSelect={setSelectedProduct}
                          search={handleSearchProducts}
                          searchSuccess={searchSuccess}
                          singleStoreMode={singleStoreMode}
                          loading={loadingProducts === LoadingState.Pending}
                          ref={productResultsRef}
                          searchBarRef={productSearchInputRef}
                          suggestCardOnClick={() => setOpenWishFormOpen(true)}
                        >
                          {products.length && (
                            <Box pb={4} px={4} textAlign="center">
                              <Button
                                id="sendWishButton"
                                variant="contained"
                                color="primary"
                                onClick={handleCreateWish}
                                focusRipple
                                endIcon={<ArrowForwardIcon fontSize="inherit" />}
                                size="large"
                                buttonRef={sendWishButtonRef}
                                tabIndex="0"
                                disabled={!isStoreSelected || !isProductSelected}
                              >
                                {Prismic.getText(homepageContent.send_wish_button)}
                              </Button>
                            </Box>
                          )}
                        </ProductSearch>
                      </Suspense>
                    </Box>
                  </div>
                </Grid>
              </Fade>
            </Collapse>
          )}
          <Grid item>
            <Box px={{ md: 4 }} py={2}>
              <Collapse in={openWishFormOpen} collapsedHeight={0} timeout={500}>
                <Fade in={openWishFormOpen} timeout={500}>
                  <Box>
                    <OpenWishForm onSave={handleCreateOpenWish} onCancel={closeOpenWishForm} />
                  </Box>
                </Fade>
              </Collapse>
              <Collapse in={!openWishFormOpen} collapsedHeight={0} timeout={500}>
                <Fade in={!openWishFormOpen} timeout={500}>
                  <Box px={2} py={4} id="sendWishOutsideSelectionLink" textAlign="center">
                    <ContentWrapper>{Prismic.getRichText(homepageContent?.send_wish_outside_selection)}</ContentWrapper>

                    <Link
                      component="button"
                      variant="body1"
                      onClick={openOpenWishForm}
                      underline="always"
                      color="textSecondary"
                    >
                      {Prismic.getText(homepageContent?.open_wish_form_open_link)}
                    </Link>
                  </Box>
                </Fade>
              </Collapse>
            </Box>
          </Grid>
        </main>
      </Grid>
    </Container>
  );
};

export default Home;
