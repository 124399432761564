import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { LanguageSelect } from "../LanguageSelect/LanguageSelect";
import { useLanguage } from "../../hooks/useLanguage";

interface Props {
  logo?: JSX.Element;
}
export const Header = ({ logo }: Props): JSX.Element => {
  const { lang, setLang } = useLanguage();

  const handleSetLanguage = (value: string) => {
    setLang(value);
    // Forced refresh is needed in order to reload OneTrust scripts after language change
    window.location.reload();
  };

  return (
    <Box marginTop={4}>
      <Grid container direction="row" item xs>
        <Grid item xs={4}>
          &nbsp;
        </Grid>
        <Grid item xs={4}>
          <Box textAlign="center">{logo}</Box>
        </Grid>
        <Grid item xs={4} container direction="row" justify="flex-end">
          <nav>
            <LanguageSelect onSelect={handleSetLanguage} selected={lang} />
          </nav>
        </Grid>
      </Grid>
    </Box>
  );
};
