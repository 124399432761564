import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

interface Props {
  onSelect: (value: string) => void;
  selected: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      minWidth: "2rem",
      "&:hover": {
        color: "rgb(255, 255, 255)",
        backgroundColor: "rgba(255, 255, 255, 0.25)",
      },
    },
  }),
);

export const LanguageSelect = ({ onSelect, selected }: Props): JSX.Element => {
  const classes = useStyles();

  const options = [
    { label: "FI", value: "fi", title: "Suomeksi" },
    { label: "SV", value: "sv-se", title: "På svenska" },
    { label: "EN", value: "en-gb", title: "In English" },
  ];

  return (
    <Box paddingRight={{ xs: 2, sm: 4 }}>
      {options
        .filter((opt) => opt.value !== selected)
        .map((opt) => (
          <Button
            id={`lang-${opt.label}`}
            key={`lang-${opt.value}`}
            lang={opt.label.toLowerCase()}
            onClick={() => onSelect(opt.value)}
            color="secondary"
            size="medium"
            variant="text"
            title={opt.title}
            aria-label={opt.title}
            className={classes.root}
          >
            {opt.label}
          </Button>
        ))}
    </Box>
  );
};
