import useSWR from "swr";
import { Store } from "../api/stores";

const fetcher = (input: RequestInfo, init: RequestInit) => fetch(input, init).then((res) => res.json());

const collator = new Intl.Collator(["fi", "sv"]);

export function useAllStores(): { stores: Store[]; isLoading: boolean; isError: boolean } {
  const { data, error } = useSWR<Store[]>(`${process.env.REACT_APP_API_URL}/stores`, fetcher);

  const stores: Store[] = data || [];
  if (!error && stores.length > 0) {
    stores.sort((a: Store, b: Store) => collator.compare(a.name, b.name));
  }

  return {
    stores: stores,
    isLoading: !error && !data,
    isError: error,
  };
}
