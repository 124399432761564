import React from "react";
import { makeStyles, TextField } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { ProductGroupOption, productGroupsForOpenWish } from "../../api/productGroups";
import { LanguageCode } from "../../hooks/useLanguage";

const useStyles = makeStyles({
  groupLabel: {
    backgroundColor: "rgb(142, 37, 85)",
    color: "rgb(255, 255, 255)",
  },
});

interface Props {
  lang: LanguageCode;
  name: string;
  onChange: (...event: any[]) => void;
  onBlur: () => void;
  value: ProductGroupOption;
  required?: boolean;
  error?: boolean;
  placeholder: string;
  ariaLabel?: string;
  inputRef?: React.Ref<HTMLInputElement>;
}

export const ProductGroupSelect = ({
  lang,
  name,
  required = false,
  error = false,
  placeholder,
  ariaLabel,
  inputRef,
  onChange,
  onBlur,
  value,
}: Props): JSX.Element => {
  const classes = useStyles();

  const filterOptions = createFilterOptions({
    ignoreAccents: false,
    ignoreCase: true,
    stringify: (option: ProductGroupOption) => option.name[lang],
  });

  return (
    <Autocomplete
      id={name}
      classes={classes}
      aria-required={required ? "true" : "false"}
      aria-label={ariaLabel}
      fullWidth
      options={productGroupsForOpenWish || []}
      filterOptions={filterOptions}
      getOptionLabel={(option) => option.name[lang]}
      getOptionSelected={(option, value) => option.id === value.id}
      renderInput={(props) => (
        <TextField
          {...props}
          required={required}
          error={error}
          name={name}
          variant="outlined"
          placeholder={placeholder}
        />
      )}
      onChange={(_, value: ProductGroupOption | null) => onChange(value)}
      onClose={onBlur}
      value={value}
      ref={inputRef}
    />
  );
};
