import React from "react";
import { useSelector } from "react-redux";
import { Box, Button, TextField, Typography, InputLabel } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Controller, useForm } from "react-hook-form";
import { useLanguage } from "../../hooks/useLanguage";
import { RootState } from "../../store";
import { ProductGroupOption } from "../../api/productGroups";
import { CountrySelect } from "../CountrySelect/CountrySelect";
import { Country } from "../../utils/countries/countries";
import { ProductGroupSelect } from "../ProductGroupSelect/ProductGroupSelect";
import * as Prismic from "../PrismicContent";

interface Props {
  onSave: (wish: FormInputs) => void;
  onCancel: () => void;
}
export interface FormInputs {
  productGroup: ProductGroupOption;
  country?: Country;
  wishText: string;
}
export const OpenWishForm = ({ onSave, onCancel }: Props): JSX.Element => {
  const { lang } = useLanguage();
  const content = useSelector((state: RootState) => state.content.content?.openWishForm);

  const { register, handleSubmit, control, reset, errors } = useForm<FormInputs>();

  const onSubmit = (data: FormInputs) => {
    onSave(data);
  };

  return (
    <Box bgcolor="white" color="white" borderRadius={4} px={{ xs: 0, sm: 0, md: 4 }} py={{ xs: 4, sm: 4, md: 4 }}>
      <Box style={{ maxWidth: "38.75rem", margin: "0 auto" }}>
        <Box py={2}>
          <Typography variant="body1" color="textPrimary" component="div">
            {Prismic.getTitle(content?.title)}
          </Typography>
        </Box>
        <Box py={2}>
          <Typography variant="body1" color="textPrimary" component="div" align="center">
            {Prismic.getRichText(content?.open_wish_description)}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box pb={4} px={2} textAlign="center">
            <InputLabel htmlFor="productGroup" required>
              {Prismic.getText(content?.product_group_aria_text)}
            </InputLabel>
            <Controller
              rules={{ required: true }}
              render={({ onChange, onBlur, value, name }) => (
                <ProductGroupSelect
                  lang={lang}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  required
                  ariaLabel={Prismic.getText(content?.product_group_aria_text)}
                  placeholder={Prismic.getText(content?.product_group_placeholder)}
                  inputRef={register}
                  error={!!errors.productGroup}
                />
              )}
              control={control}
              name="productGroup"
              defaultValue={null}
            />
          </Box>
          <Box pb={4} px={2} textAlign="center">
            <InputLabel htmlFor="country">{Prismic.getText(content?.country_list_aria_text)}</InputLabel>
            <Controller
              render={({ onChange, onBlur, value, name }) => (
                <CountrySelect
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  lang={lang}
                  popularGroupLabel={Prismic.getText(content?.country_list_popular_group)}
                  otherGroupLabel={Prismic.getText(content?.country_list_other_group)}
                  placeholder={Prismic.getText(content?.country_list_placeholder)}
                />
              )}
              control={control}
              name="country"
              error={!!errors.country}
              defaultValue={null}
            />
          </Box>
          <Box pb={4} px={2} textAlign="center">
            <InputLabel htmlFor="wishText" required>
              {Prismic.getText(content?.wish_text_aria_text)}
            </InputLabel>
            <TextField
              id="wishText"
              aria-required="true"
              aria-label={Prismic.getText(content?.wish_text_aria_text)}
              name="wishText"
              variant="outlined"
              fullWidth
              multiline
              rows="3"
              placeholder={Prismic.getText(content?.wish_text_placeholder)}
              required
              error={!!errors.wishText}
              inputRef={register}
            />
          </Box>
          <Box px={2} textAlign="center">
            <Button
              id="sendWishButton"
              variant="contained"
              color="primary"
              type="submit"
              focusRipple
              endIcon={<ArrowForwardIcon fontSize="inherit" />}
              size="large"
              tabIndex="0"
            >
              {Prismic.getText(content?.send_wish_button)}
            </Button>
          </Box>
        </form>
        <Box pb={4} pt={2} textAlign="center">
          <Button
            variant="text"
            focusRipple
            tabIndex="0"
            onClick={() => {
              reset();
              onCancel();
            }}
          >
            {Prismic.getText(content?.back_link)}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
