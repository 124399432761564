import { LanguageCode } from "../../hooks/useLanguage";
import countriesFi from "./fi.json";
import countriesSv from "./sv.json";
import countriesEn from "./en.json";

// JSON files originally from:
// https://github.com/michaelwittig/node-i18n-iso-countries/tree/master/langs
//
// If update is needed, please check the manually added entries for GB-* .
//
export interface Country {
  value: string;
  name: string;
  popular: boolean;
  group: string;
}

const popularCountries = [
  "AR",
  "AU",
  "BE",
  "CL",
  "GB-ENG",
  "ES",
  "ZA",
  "IE",
  "IT",
  "AT",
  "CA",
  "PT",
  "FR",
  "SE",
  "DE",
  "GB-SCT",
  "FI",
  "DK",
  "CZ",
  "HU",
  "NZ",
  "RU",
  "EE",
  "US",
  "GB",
];
export const getCountries = (lang: LanguageCode): Country[] => {
  const countries = lang === LanguageCode.EN ? countriesEn : lang === LanguageCode.SV ? countriesSv : countriesFi;
  const countryOptions = Object.entries(countries.countries).map(([value, name]) => ({
    value,
    name: name.toString(),
    popular: popularCountries.includes(value),
    group: "",
  }));
  return countryOptions;
};
