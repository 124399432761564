import React from "react";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { Country, getCountries } from "../../utils/countries/countries";
import sortBy from "lodash/sortBy";
import { makeStyles, TextField } from "@material-ui/core";
import { LanguageCode } from "../../hooks/useLanguage";

const useStyles = makeStyles({
  groupLabel: {
    backgroundColor: "rgb(142, 37, 85)",
    color: "rgb(255, 255, 255)",
  },
});

interface Props {
  name: string;
  onChange: (...event: any[]) => void;
  onBlur: () => void;
  value: Country;
  lang: LanguageCode;
  popularGroupLabel: string;
  otherGroupLabel: string;
  placeholder: string;
  inputRef?: React.Ref<HTMLInputElement>;
}
export const CountrySelect = ({
  name,
  onChange,
  onBlur,
  value,
  lang,
  popularGroupLabel,
  otherGroupLabel,
  placeholder,
  inputRef,
}: Props): JSX.Element => {
  const classes = useStyles();
  const countries = getCountries(lang);
  const countryOptions: Country[] = sortBy<Country>(
    countries.map((c) => ({
      ...c,
      group: c.popular ? popularGroupLabel : otherGroupLabel,
    })),
    ["group", "name"],
  );

  const countryFilterOptions = createFilterOptions({
    ignoreAccents: false,
    ignoreCase: true,
    stringify: (option: Country) => option.name,
  });

  return (
    <Autocomplete
      id={name}
      classes={classes}
      fullWidth
      options={countryOptions.sort((a, b) => {
        if (a.group === b.group) {
          return a.name.localeCompare(b.name);
        }
        return -a.group.localeCompare(b.group);
      })}
      groupBy={(option) => option.group}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) => option.value === value.value}
      filterOptions={countryFilterOptions}
      renderInput={(props) => <TextField {...props} name={name} variant="outlined" placeholder={placeholder} />}
      onChange={(_, value: Country | null) => onChange(value)}
      onClose={onBlur}
      value={value}
      ref={inputRef}
    />
  );
};
